import React, {useRef, useState, useEffect} from 'react';
import FuncUtil from "../../utils/FuncUtil";
import xray from "../../assets/images/pages/chest-xray.png";
import PatientService from "../../services/PatientService";

function MassUploadXray(props: any) {
    const {patient,onSuccess} = props;

    const [isSaved, setIsSaved] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const directoryRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (directoryRef.current !== null) {
            directoryRef.current.setAttribute("directory", "");
            directoryRef.current.setAttribute("webkitdirectory", "");
        }
    }, [directoryRef]);

    const completeHandler =  (e: any) => {
        console.log(patient)
        PatientService.massUploadXray(selectedFiles).then((response) => {
            setIsSaved(true)
            setIsError(false);
            setMessage("X-Ray Successfully Uploaded!");
            setTimeout(args => {
                setIsSaved(false);
            }, 2000);
        }).catch(reason => {
            setIsError(true);
            setIsSaved(false);
            setMessage(reason.response.data.message);
            setTimeout(args => {
                setIsError(false);
            }, 2000);
        })
    }

    const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);

            setSelectedFiles(filesArray);
        }
    }

    return (
        <div className={'row'}>
            <div className="col-12 p-2 text-center">
                {isSaved ? (
                    <div className="alert alert-success mb-1 alert-icon-left" role="alert">
                        <span className="alert-icon"> <i className="ft-thumbs-up"></i> </span>
                        {message}
                    </div>) : ''}
                {isError ? (
                    <div className="alert alert-danger mb-1 alert-icon-left" role="alert">
                        <span className="alert-icon"> <i className="ft-thumbs-up"></i> </span>
                        {message}
                    </div>) : ''}
                {/* <img src={image} alt="captured" style={{maxHeight:250,height:'100%'}}/> */}
                <div className="button-group text-center mt-1">
                    <input type="file" 
                            className={'hidden'} 
                            ref={directoryRef}
                            multiple
                            onChange={uploadFiles}
                            accept={'.jpg,.png,.webp,.jpeg'} id={'xray-input'}
                            />
                    <button className={'btn btn-info width-200 btn-glow round mx-1'} onClick={() => {document.getElementById('xray-input')?.click();}}>
                        <i className="ft-camera font-medium-3 pt-1"> Upload X-Ray</i>
                    </button>
                    <button className={'btn btn-info width-200 btn-glow round mx-1'} onClick={completeHandler}>
                        <i className="ft-upload font-medium-3 pt-1"> Attach</i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MassUploadXray;
