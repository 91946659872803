import React, { useEffect, useState } from 'react';
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import { FORM, MODEL } from "../../utils/FormFields";
import PatientService from "../../services/PatientService";
import RefValuesService from "../../services/RefValuesService";
import moment from "moment";
import ReactToPrint from "react-to-print";
import AuthService from "../../services/AuthService";
import FuncUtil from "../../utils/FuncUtil";
import http from '../../config/httpConfig';
import { API_ROUTES, BASE_URL } from '../../utils/constants';
import FormSelect from '../ui/FormSelect';
import UserProfileDetail from './UserProfileDetail';

function PatientReportContent(props: any) {
  const configuration = AuthService.getConfiguration();
  const permissionA = AuthService.getPermission('REPORT_EDIT_USER_A');
  const permissionB = AuthService.getPermission('REPORT_EDIT_USER_B');
  const permissionC = AuthService.getPermission('REPORT_EDIT_USER_C');
  const permissionF = AuthService.getPermission('FINAL_STATUS');
  const [loaded, setLoaded] = useState(false);
  const [patient, setPatient] = useState(props?.patient);
  const [action, setAction] = useState("none");

  const [report, setReport] = useState(MODEL.REPORT);
  const [refValue, setRefValue] = useState(MODEL.REF_VALUE);
  const pageStyle = `
      @page {
        size: auto;
        margin: 0;
      }
      @media print {
        html, body {
          width: 200mm;
          height: 297mm;
        }
        body {
          margin: 0;
        }
      }
    `;

  useEffect(() => {
    PatientService.findById(patient.id).then(response => {
      setPatient(response.data);
      setReport(response.data.report);
    })
    RefValuesService.find().then(response => {
      setRefValue(response.data);
    })
  }, [])



  const reported = (e: any) => {
    console.log(e.target.name);
    console.log(e.target.value);
    console.log(patient.id)
    http.put(API_ROUTES.PATIENT_REPORT_VALUES_PUT, {
      id: patient.id,
      columnName: e.target.name,
      value: e.target.value,
    }).then(response => {
      console.log(response);
    })

  }

  const handleButtonPrint = ()=>{
    setAction('print-view');
  }

  return (
    <div className="card mb-0" style={{
      height:"100%"}}>
      <div className="card-content collapse show">
        <div className="card-body">
          <div className="row">
            <div className="col-8">
              <h3 className="font-size mb-2">Medical Test Report of {patient.fullName}</h3>
            </div>
            <div className="col-4">
              {/* <ReactToPrint

                documentTitle={patient.passportNo}
                content={() => document.getElementById('patient-report-print-content')}
                trigger={() => <button className="btn btn-info pull-right">Print</button>} /> */}
                <button type="button" className="btn btn-info pull-right"
                        onClick={handleButtonPrint}>Print
                </button>
            </div>
          </div>
          <div className={'printable mx-3 my-2'} id={'patient-report-print-content'}>
            <div className={'row'}>
              <div className="col-12 text-center">
                <div className="px-0 row  list-unstyled black">
                  <div className='col-2  py-2 border-right-0 '>
                    <img src={configuration.reportCompanyLogo} className="mb-0" alt='' height={100} />
                  </div>
                  <div className='col-10 py-2 '>
                    <div>
                      <h3 className='text-bold-600'>{configuration.businessName}</h3>
                      <h4>Address : {configuration.invoiceAddress}</h4>
                    </div>
                    <div className='d-flex justify-content-around'>
                      <p>CONTACT NO : {configuration.reportContactNumber} </p>
                      <p>Website :{configuration.websiteUrl} </p>
                      <p>Report Download : {configuration.reportUrl}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 text-center">
                <h4 className={'font-weight-bolder'}>MEDICAL TEST REPORT</h4>
              </div>
            </div>
            <table className="table-bordered table report-header">
              <tbody>
                <tr className={'text-center'}>
                  <td className="black width-20-per text-center">
                    <span>PATIENT ID : {patient.regNo}</span>
                    <Barcode value={patient.regNo ? patient.regNo : 'NA'} marginTop={10} displayValue={false} width={2} height={50} />
                    <br />
                    <span>REG DATE : {moment(FuncUtil.toDateTime(patient?.createdDate)).format('DD-MM-YYYY')}
                    </span>
                  </td>
                  <td className="black width-20-per text-center">
                    <img src={patient?.photo} alt="" style={{ height: 120, maxWidth: 150 }} />
                  </td>
                  <td className="black width-20-per text-center">
                    <img src={patient?.fingerPrint} alt="" style={{ height: 120, maxWidth: 150 }} />
                  </td>
                  <td className="black width-20-per text-center">
                    <img src={report?.xrayImage} alt="" style={{ height: 120, maxWidth: 150 }} />
                  </td>
                  <td className="black width-20-per text-center">
                    <QRCode size={100} className={'qr-image mt-1'} value={patient?.qr ? patient.qr : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="text-center font-weight-bolder font-size black">DATE : {moment(FuncUtil.toDateTime(report?.modifiedDate)).format('DD-MM-YYYY hh:mm A')}</td>
                  <td className="text-center font-weight-bolder font-size black">PHOTO</td>
                  <td className="text-center font-weight-bolder font-size black">THUMB PRINT</td>
                  <td className="text-center font-weight-bolder font-size black">X –RAY IMAGE</td>
                  <td className="text-center font-weight-bolder font-size black">QR CODE</td>
                </tr>
              </tbody>
            </table>
            <table className="table-bordered table">
              <tbody>
                <tr>
                  <td className="font-size black">NAME</td>
                  <td className="font-size black">{patient.fullName}</td>
                  <td className="font-size black">PATIENT ID</td>
                  <td className="font-size black">{patient.regNo}</td>
                </tr>
                <tr>
                  <td className="font-size black">GENDER</td>
                  <td className="font-size black">{patient.gender}</td>
                  <td className="font-size black">PROFESSION</td>
                  <td className="font-size black">{patient.profession}</td>
                </tr>
                <tr>
                  <td className="font-size black">DATE OF BIRTH</td>
                  <td className="font-size black">{moment(patient.dateOfBirth).format('DD-MM-YYYY')}</td>
                  <td className="font-size black">TRAVELING TO</td>
                  <td className="font-size black">{patient.travelingTo}</td>
                </tr>
                <tr>
                  <td className="font-size black">PASSPORT NO.</td>
                  <td className="font-size black">{patient.passportNo}</td>
                  <td className="font-size black">CONTACT NUMBER</td>
                  <td className="font-size black">{patient.mobile}</td>
                </tr>
                <tr>
                  <td className="font-size black">NATIONALITY</td>
                  <td className="font-size black">Bangladeshi</td>
                  <td className="font-size black">NAME OF AGENCY / SUB AGENT</td>
                  <td className="font-size black">{patient.agentOrAgencyName}</td>
                </tr>
              </tbody>
            </table>



            <div className="row">
              <div className="col-md-6 pr-0">
                <table className="table-bordered table ">
                  <tbody>
                    {
                      permissionA.edit ?
                        <>
                          <tr>
                            <td className="text-center font-weight-bolder font-size black" colSpan={5}>MEDICAL
                              EXAMINATION
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black">TYPE OF EXAMINATION</td>
                            <td className="text-center font-weight-bolder font-size black" colSpan={2}>RESULTS</td>
                            <td className="text-center font-weight-bolder font-size black" colSpan={2}>REF. VALUE</td>
                          </tr>
                          <tr>
                            <td rowSpan={2} className="font-size black">VISUAL ACUITY</td>
                            <td colSpan={2} className="text-center font-size black">UNAIDED</td>
                            <td colSpan={2} className="text-center font-size black">AIDED</td>
                          </tr>
                          <tr>
                            <td className="text-center font-size black">RIGHT</td>
                            <td className="text-center font-size black">LEFT</td>
                            <td className="text-center font-size black">RIGHT</td>
                            <td className="text-center font-size black">LEFT</td>
                          </tr>
                          <tr>
                            <td className="font-size black">DISTANT</td>
                            <td className="text-center font-size black">
                              <input
                                onChange={reported}
                                type="text"
                                name='distantUnaidedRight'
                                defaultValue={report?.distantUnaidedRight}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">

                              <input
                                onChange={reported}
                                type="text"
                                name='distantUnaidedLeft'
                                defaultValue={report?.distantUnaidedLeft}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black">
                              <input
                                onChange={reported}
                                type="text"
                                name='distantAidedRight'
                                defaultValue={report?.distantAidedRight}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">


                              <input
                                onChange={reported}
                                type="text"
                                name='distantAidedLeft'
                                defaultValue={report?.distantAidedLeft}
                                className='medicalInput border-0 w-100 ' /></td>
                          </tr>
                          <tr>
                            <td className="font-size black">NEAR</td>
                            <td className="text-center font-size black">
                              <input
                                onChange={reported}
                                type="text"
                                name='nearUnaidedRight'
                                defaultValue={report?.nearUnaidedRight}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black">

                              <input
                                onChange={reported}
                                type="text"
                                name='nearUnaidedLeft'
                                defaultValue={report?.nearUnaidedLeft}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">
                              <input
                                onChange={reported}
                                type="text"
                                name='nearAidedRight'
                                defaultValue={report?.nearAidedRight}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">
                              <input
                                onChange={reported}
                                type="text"
                                name='nearAidedLeft'
                                defaultValue={report?.nearAidedLeft}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">CLEAR VISION</td>
                            <td colSpan={4} className="text-center font-size black">
                              <input
                                onChange={reported}
                                type="text"
                                name='clearVision'
                                defaultValue={report?.clearVision}
                                className='medicalInput border-0 w-100 ' /></td>
                          </tr>
                          <tr>
                            <td className="font-size black" rowSpan={2}>HEARING (EAR)</td>
                            <td className="text-center font-size black">L</td>
                            <td className="text-center font-size black">
                              <input
                                type="text"
                                onChange={reported}
                                name='earLeft'
                                defaultValue={report?.earLeft}
                                className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.earLeft}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-center font-size black">R</td>
                            <td className="text-center font-size black">
                              <input
                                type="text"
                                name='earRight'
                                onChange={reported}
                                defaultValue={report?.earRight}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.earRight}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-size black font-weight-bolder" colSpan={5}>
                              SYSTEMIC EXAM: CARDIO - VASCULAR
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">B.P</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='bloodPressure'
                                onChange={reported}
                                defaultValue={report?.bloodPressure}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.bloodPressure}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HEART</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='heart'
                                onChange={reported}
                                defaultValue={report?.heart}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2} rowSpan={2}>
                              {refValue?.heart}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">PULSE</td>
                            <td className="text-center font-size black" colSpan={2}>

                              <input
                                type="text"
                                name='pulse'
                                onChange={reported}
                                defaultValue={report?.pulse}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>
                              RESPIRATORY EXAM
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">LUNGS</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='lungs'
                                onChange={reported}
                                defaultValue={report?.lungs}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.lungs}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">GASTROINTESTINAL ABDOMEN</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              <input
                                type="text"
                                name='gastrointestinalAbdomen'
                                onChange={reported}
                                defaultValue={report?.gastrointestinalAbdomen}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.gastrointestinalAbdomen}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>OTHERS</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HEIGHT (CM)</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='height'
                                defaultValue={report?.height}
                                onChange={reported}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>

                              {refValue?.height}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">WEIGHT (KG)</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='weight'
                                onChange={reported}
                                defaultValue={report?.weight}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.weight}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HERNIA</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='hernia'
                                onChange={reported}
                                defaultValue={report?.hernia}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.hernia}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">VARICOSEVEINS</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              <input
                                type="text"
                                name='varicoseVeins'
                                onChange={reported}
                                defaultValue={report?.varicoseVeins}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.varicoseVeins}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">DEFORMITIES</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              <input
                                type="text"
                                name='deformities'
                                onChange={reported}
                                defaultValue={report?.deformities}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.deformities}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">SKIN</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                onChange={reported}
                                name='skin'
                                defaultValue={report?.skin}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.skin}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">C.N.S.</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='cns'
                                onChange={reported}
                                defaultValue={report?.cns}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.cns}


                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">EXTREMITIES</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              <input
                                type="text"
                                name='extremities'
                                onChange={reported}
                                defaultValue={report?.extremities}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.extremities}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">PSYCHIATRY</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='psychiatry'
                                onChange={reported}
                                defaultValue={report?.psychiatry}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.psychiatry}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>VENEREAL
                              DISEASES
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">SYMPTOMS</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='symptoms'
                                onChange={reported}
                                defaultValue={report?.symptoms}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>

                              {refValue?.symptoms}

                            </td>
                          </tr>
                        </>
                        :
                        <>
                          <tr>
                            <td className="text-center font-weight-bolder font-size black" colSpan={5}>MEDICAL
                              EXAMINATION
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black">TYPE OF EXAMINATION</td>
                            <td className="text-center font-weight-bolder font-size black" colSpan={2}>RESULTS</td>
                            <td className="text-center font-weight-bolder font-size black" colSpan={2}>REF. VALUE</td>
                          </tr>
                          <tr>
                            <td rowSpan={2} className="font-size black">VISUAL ACUITY</td>
                            <td colSpan={2} className="text-center font-size black">UNAIDED</td>
                            <td colSpan={2} className="text-center font-size black">AIDED</td>
                          </tr>
                          <tr>
                            <td className="text-center font-size black">RIGHT</td>
                            <td className="text-center font-size black">LEFT</td>
                            <td className="text-center font-size black">RIGHT</td>
                            <td className="text-center font-size black">LEFT</td>
                          </tr>
                          <tr>
                            <td className="font-size black">DISTANT</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='distantUnaidedRight'
                                onChange={reported}
                                defaultValue={report?.distantUnaidedRight}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">

                              <input

                                type="text"
                                name='distantUnaidedLeft'
                                onChange={reported}
                                defaultValue={report?.distantUnaidedLeft}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='distantAidedRight'
                                onChange={reported}
                                defaultValue={report?.distantAidedRight}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">


                              <input

                                type="text"
                                name='distantAidedLeft'
                                onChange={reported}
                                defaultValue={report?.distantAidedLeft}
                                className='medicalInput border-0 w-100 ' /></td>
                          </tr>
                          <tr>
                            <td className="font-size black">NEAR</td>
                            <td className="text-center font-size black">
                              {report?.nearUnaidedRight}


                            </td>
                            <td className="text-center font-size black">
                              {report?.nearUnaidedLeft}
                            </td>
                            <td className="text-center font-size black">
                              {report?.nearAidedRight}

                            </td>
                            <td className="text-center font-size black">
                              {report?.nearAidedLeft}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">CLEAR VISION</td>
                            <td colSpan={4} className="text-center font-size black">
                              {report?.clearVision}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black" rowSpan={2}>HEARING (EAR)</td>
                            <td className="text-center font-size black">L</td>
                            <td className="text-center font-size black">
                              {report?.earLeft}
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.earLeft}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-center font-size black">R</td>
                            <td className="text-center font-size black">
                              {report?.earRight}
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.earRight}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-size black font-weight-bolder" colSpan={5}>
                              SYSTEMIC EXAM: CARDIO - VASCULAR
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">B.P</td>
                            <td className="text-center font-size black" colSpan={2}>

                              {report?.bloodPressure}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.bloodPressure}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HEART</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.heart}

                            </td>
                            <td className="text-center font-size black" colSpan={2} rowSpan={2}>
                              {refValue?.heart}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">PULSE</td>
                            <td className="text-center font-size black" colSpan={2}>

                              {report?.pulse}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>
                              RESPIRATORY EXAM
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">LUNGS</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.lungs}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.lungs}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">GASTROINTESTINAL ABDOMEN</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              {report?.gastrointestinalAbdomen}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.gastrointestinalAbdomen}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>OTHERS</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HEIGHT (CM)</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.height}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.height}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">WEIGHT (KG)</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.weight}


                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.weight}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HERNIA</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.hernia}


                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.hernia}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">VARICOSEVEINS</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              {report?.varicoseVeins}


                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.varicoseVeins}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">DEFORMITIES</td>
                            <td className="text-center font-size black"
                              colSpan={2}>
                              {report?.deformities}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>

                              {refValue?.deformities}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">SKIN</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.skin}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.skin}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">C.N.S.</td>
                            <td className="text-center font-size black" colSpan={2}>
                              {report?.cns}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.cns}


                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">EXTREMITIES</td>
                            <td className="text-center font-size black"
                              colSpan={2}>

                              {report?.extremities}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>

                              {refValue?.extremities}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">PSYCHIATRY</td>
                            <td className="text-center font-size black" colSpan={2}>

                              {report?.psychiatry}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>

                              {refValue?.psychiatry}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>VENEREAL
                              DISEASES
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">SYMPTOMS</td>
                            <td className="text-center font-size black" colSpan={2}>

                              {report?.symptoms}

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue?.symptoms}
                            </td>
                          </tr>
                        </>
                    }
                    {
                      permissionC.edit ?
                        <>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>X-RAY
                              INVESTIGATION
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">CHEST X-RAY</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='chestXray'
                                onChange={reported}
                                defaultValue={report?.chestXray}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>{refValue.chestXray}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ECG</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='ecg'
                                onChange={reported}
                                defaultValue={report?.ecg}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>{refValue.ecg}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">Remarks</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='xrayImageRemarks'
                                onChange={reported}
                                defaultValue={report?.xrayImageRemarks}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}></td>
                          </tr>
                        </> :
                        <>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>X-RAY
                              INVESTIGATION
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">CHEST X-RAY</td>
                            <td className="text-center font-size black" colSpan={4}>{report?.chestXray}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ECG</td>
                            <td className="text-center font-size black" colSpan={2}>{report?.ecg}</td>
                            <td className="text-center font-size black" colSpan={2}>{refValue.ecg}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">Remarks</td>
                            <td className="text-center font-size black" colSpan={4}>{report?.xrayImageRemarks}</td>
                          </tr>
                        </>
                    }
                    {
                      permissionB.edit ?
                        <>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>DRUG TEST
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">THC</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='thc'
                                onChange={reported}

                                defaultValue={report?.thc}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue.thc}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">MOP</td>
                            <td className="text-center font-size black" colSpan={2}>

                              <input
                                type="text"
                                name='mop'
                                onChange={reported}
                                defaultValue={report?.mop}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue.mop}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">AMP</td>
                            <td className="text-center font-size black" colSpan={2}>
                              <input
                                type="text"
                                name='amp'
                                onChange={reported}
                                defaultValue={report?.amp}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black" colSpan={2}>
                              {refValue.amp}


                            </td>
                          </tr>
                        </> : <>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={5}>DRUG TEST
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">THC</td>
                            <td className="text-center font-size black" colSpan={2}>{report?.thc}</td>
                            <td className="text-center font-size black" colSpan={2}>{refValue.thc}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">MOP</td>
                            <td className="text-center font-size black" colSpan={2}>{report?.mop}</td>
                            <td className="text-center font-size black" colSpan={2}>{refValue.mop}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">AMP</td>
                            <td className="text-center font-size black" colSpan={2}>{report?.amp}</td>
                            <td className="text-center font-size black" colSpan={2}>{refValue.amp}</td>
                          </tr>
                        </>
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 pl-0">
                <table className="table-bordered table ">

                  <tbody>
                    {

                      permissionB.edit ?
                        <>
                          <tr>
                            <td className="text-center font-weight-bolder font-size black" colSpan={3}>LABORATORY
                              INVESTIGATION
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center font-weight-bolder font-size black">TYPE OF EXAMINATION</td>
                            <td className="text-center font-weight-bolder font-size black" colSpan={1}>RESULTS</td>
                            <td className="text-center font-weight-bolder font-size black">REF. VALUE</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>URINE</td>
                          </tr>
                          <tr>
                            <td className="font-size black">SUGAR</td>
                            <td className="text-center font-size black">
                              <input
                                type="text"
                                name='sugar'
                                onChange={reported}
                                defaultValue={report?.sugar}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black">

                              {refValue.sugar}


                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">ALBUMIN</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='albumin'
                                onChange={reported}
                                defaultValue={report?.albumin}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">
                              {refValue.albumin}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='urineBilharziasis'
                                onChange={reported}
                                defaultValue={report?.urineBilharziasis}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">
                              {refValue.urineBilharziasis}


                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">PREGNANCY TEST</td>
                            <td className="text-center font-size black">

                              <input

                                type="text"
                                name='pregnancy'
                                onChange={reported}
                                defaultValue={report?.pregnancy}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black">

                              {refValue.pregnancy}


                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">OTHERS</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='others'
                                onChange={reported}
                                defaultValue={report?.others}
                                className='medicalInput border-0 w-100 ' />

                            </td>
                            <td className="text-center font-size black">
                              {refValue.others}

                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>STOOL R/E (IF
                              REQUIRED)
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HELMINTHS</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='helminths'
                                onChange={reported}
                                defaultValue={report?.helminths}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">
                              {refValue.helminths}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">GIARDIA</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='giardia'
                                onChange={reported}
                                defaultValue={report?.giardia}
                                className='medicalInput border-0 w-100 ' />
                            </td>
                            <td className="text-center font-size black">
                              {refValue.giardia}

                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='stoolBilharziasis'
                                onChange={reported}
                                defaultValue={report?.stoolBilharziasis}
                                className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">
                              {refValue.stoolBilharziasis}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">CULTURE</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='culture'
                                onChange={reported}
                                defaultValue={report?.culture}
                                className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">
                              {refValue.culture}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>BLOOD-CBC &
                              GROUPING
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">MALARIA</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='malaria'
                                onChange={reported}
                                defaultValue={report?.malaria}
                                className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">
                              {refValue.malaria}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">MICROFILARIA</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='microfilaria'
                                onChange={reported}
                                defaultValue={report?.microfilaria}
                                className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">
                              {refValue.microfilaria}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">BLOOD GROUP</td>
                            <td className="text-center font-size black">
                              <input

                                type="text"
                                name='bloodGroup'
                                onChange={reported}
                                defaultValue={report?.bloodGroup}
                                className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.bloodGroup}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HEMOGLOBIN</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='haemoglobin'
                              onChange={reported}
                              defaultValue={report?.haemoglobin}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black"> {refValue.haemoglobin}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ESR</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='esr'
                              onChange={reported}
                              defaultValue={report?.esr}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.esr}</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black"
                              colSpan={3}>BLOOD-BIOCHEMISTRY
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">R.B.S.</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='rbs'
                              onChange={reported}
                              defaultValue={report?.rbs}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.rbs}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">CREATININE</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='creatinine'
                              onChange={reported}
                              defaultValue={report?.creatinine}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">
                              {refValue.creatinine}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">T.BIL</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='tbil'
                              onChange={reported}
                              defaultValue={report?.tbil}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.tbil}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">SGPT</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='sgpt'
                              onChange={reported}
                              defaultValue={report?.sgpt}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black"> {refValue.sgpt}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">SGOT</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='sgot'
                              onChange={reported}
                              defaultValue={report?.sgot}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black"> {refValue.sgot}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ALP</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='alp'
                              onChange={reported}
                              defaultValue={report?.alp}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.alp}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">UREA</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='urea'
                              onChange={reported}
                              defaultValue={report?.urea}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.urea}</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>BLOOD-ELISA &
                              SEROLOGY
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">ANTI HCV</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='antiHcv'
                              onChange={reported}
                              defaultValue={report?.antiHcv}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black"> {refValue.antiHcv}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">TPHA (IF VDRL POSITIVE)</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='tpha'
                              onChange={reported}
                              defaultValue={report?.tpha}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.tpha}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">VDRL</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='vdrl'
                              onChange={reported}
                              defaultValue={report?.vdrl}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black">{refValue.vdrl}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HIV I & HIV II</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='hiv'
                              onChange={reported}
                              defaultValue={report?.hiv}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black"> {refValue.hiv}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HB<small>S</small>AG</td>
                            <td className="text-center font-size black"> <input

                              type="text"
                              name='hbsag'
                              onChange={reported}
                              defaultValue={report?.hbsag}
                              className='medicalInput border-0 w-100 ' /></td>
                            <td className="text-center font-size black"> {refValue.hbsag}</td>
                          </tr>
                        </>

                        : <>


                          <tr>
                            <td className="text-center font-weight-bolder font-size black" colSpan={3}>LABORATORY
                              INVESTIGATION
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center font-weight-bolder font-size black">TYPE OF EXAMINATION</td>
                            <td className="text-center font-weight-bolder font-size black" colSpan={1}>RESULTS</td>
                            <td className="text-center font-weight-bolder font-size black">REF. VALUE</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>URINE</td>
                          </tr>
                          <tr>
                            <td className="font-size black">SUGAR</td>
                            <td className="text-center font-size black">{report?.sugar}</td>
                            <td className="text-center font-size black">{refValue.sugar}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ALBUMIN</td>
                            <td className="text-center font-size black">{report?.albumin}</td>
                            <td className="text-center font-size black">{refValue.albumin}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                            <td className="text-center font-size black">{report?.urineBilharziasis}</td>
                            <td className="text-center font-size black">{refValue.urineBilharziasis}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">PREGNANCY TEST</td>
                            <td className="text-center font-size black">{report?.pregnancy}</td>
                            <td className="text-center font-size black">{refValue.pregnancy}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">OTHERS</td>
                            <td className="text-center font-size black">{report?.others}</td>
                            <td className="text-center font-size black">{refValue.others}</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>STOOL R/E (IF
                              REQUIRED)
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">HELMINTHS</td>
                            <td className="text-center font-size black">{report?.helminths}</td>
                            <td className="text-center font-size black">{refValue.helminths}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">GIARDIA</td>
                            <td className="text-center font-size black">{report?.giardia}</td>
                            <td className="text-center font-size black">{refValue.giardia}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                            <td className="text-center font-size black">{report?.stoolBilharziasis}</td>
                            <td className="text-center font-size black">{refValue.stoolBilharziasis}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">CULTURE</td>
                            <td className="text-center font-size black">{report?.culture}</td>
                            <td className="text-center font-size black">{refValue.culture}</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>BLOOD-CBC &
                              GROUPING
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">MALARIA</td>
                            <td className="text-center font-size black">{report?.malaria}</td>
                            <td className="text-center font-size black">{refValue.malaria}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">MICROFILARIA</td>
                            <td className="text-center font-size black">{report?.microfilaria}</td>
                            <td className="text-center font-size black">{refValue.microfilaria}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">BLOOD GROUP</td>
                            <td className="text-center font-size black">{report?.bloodGroup}</td>
                            <td className="text-center font-size black">{refValue.bloodGroup}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HEMOGLOBIN</td>
                            <td className="text-center font-size black">{report?.haemoglobin}</td>
                            <td className="text-center font-size black">{refValue.haemoglobin}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ESR</td>
                            <td className="text-center font-size black">{report?.esr}</td>
                            <td className="text-center font-size black">{refValue.esr}</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black"
                              colSpan={3}>BLOOD-BIOCHEMISTRY
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">R.B.S.</td>
                            <td className="text-center font-size black">{report?.rbs}</td>
                            <td className="text-center font-size black">{refValue.rbs}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">CREATININE</td>
                            <td className="text-center font-size black">{report?.creatinine}</td>
                            <td className="text-center font-size black">{refValue.creatinine}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">T.BIL</td>
                            <td className="text-center font-size black">{report?.tbil}</td>
                            <td className="text-center font-size black">{refValue.tbil}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">SGPT</td>
                            <td className="text-center font-size black">{report?.sgpt}</td>
                            <td className="text-center font-size black">{refValue.sgpt}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">SGOT</td>
                            <td className="text-center font-size black">{report?.sgot}</td>
                            <td className="text-center font-size black">{refValue.sgot}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">ALP</td>
                            <td className="text-center font-size black">{report?.alp}</td>
                            <td className="text-center font-size black">{refValue.alp}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">UREA</td>
                            <td className="text-center font-size black">{report?.urea}</td>
                            <td className="text-center font-size black">{refValue.urea}</td>
                          </tr>
                          <tr>
                            <td className="text-left font-weight-bolder font-size black" colSpan={3}>BLOOD-ELISA &
                              SEROLOGY
                            </td>
                          </tr>
                          <tr>
                            <td className="font-size black">ANTI HCV</td>
                            <td className="text-center font-size black">{report?.antiHcv}</td>
                            <td className="text-center font-size black">{refValue.antiHcv}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">TPHA (IF VDRL POSITIVE)</td>
                            <td className="text-center font-size black">{report?.tpha}</td>
                            <td className="text-center font-size black">{refValue.tpha}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">VDRL</td>
                            <td className="text-center font-size black">{report?.vdrl}</td>
                            <td className="text-center font-size black">{refValue.vdrl}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HIV I & HIV II</td>
                            <td className="text-center font-size black">{report?.hiv}</td>
                            <td className="text-center font-size black">{refValue.hiv}</td>
                          </tr>
                          <tr>
                            <td className="font-size black">HB<small>S</small>AG</td>
                            <td className="text-center font-size black">{report?.hbsag}</td>
                            <td className="text-center font-size black">{refValue.hbsag}</td>
                          </tr>
                        </>

                    }

                  </tbody>
                </table>

              </div>
            </div>
            <>
              <div className='col-md-6 pl-0'>

                <table className="table-bordered table " >
                  <tbody  >

                    <tr>
                      <td className="text-left font-weight-bolder font-size black" colSpan={3} style={{ backgroundColor: "#FFFF00" }}>MEDICAL
                        EXAMINATION
                      </td>
                    </tr>
                    {permissionA.edit && permissionF?.roleId !== 1 ?
                      <><tr>
                        <td className="font-size black">Status</td>
                        <td className="text-center font-size black col-md-8">
                          <select className='medicalInput border-0 w-100 ' name='medicalStatus' onChange={reported} >
                            <option>SELECT</option>
                            <option value="FIT">Fit</option>
                            <option value="UNFIT">Unfit</option>
                            <option value="HELD_UP">Heldup</option>
                          </select></td>

                      </tr>
                        <tr>
                          <td className="font-size black">Remarks</td>
                          <td className="text-center font-size black">
                            <input
                              type="text"
                              name='medicalRemarks'
                              onChange={reported}
                              defaultValue={report?.medicalRemarks}
                              className='medicalInput border-0 w-100 ' />
                          </td>
                        </tr></> : <>
                        <tr>
                          <td className="font-size black">Status</td>
                          <td className="text-center font-size black col-md-8">
                            {report?.medicalStatus}</td>
                        </tr>
                        <tr>
                          <td className="font-size black">Remarks</td>
                          <td className="text-center font-size black">{report?.medicalRemarks}</td>
                        </tr></>}

                  </tbody>
                  <tbody >

                    <tr>
                      <td className="text-left font-weight-bolder font-size black" colSpan={3} style={{ backgroundColor: "#2EFE2E" }}>LABORATORY
                        INVESTIGATION
                      </td>
                    </tr>
                    {permissionB.edit && permissionF?.roleId !== 1 ?
                      <>
                        <tr>
                          <td className="font-size black">Status</td>
                          <td className="text-center font-size black col-md-8">  <select
                            name='labStatus' className='medicalInput border-0 w-100 ' onChange={reported}>
                            <option>SELECT</option>
                            <option value="FIT">Fit</option>
                            <option value="UNFIT">Unfit</option>
                            <option value="HELD_UP">Heldup</option>
                          </select></td>

                        </tr>
                        <tr>
                          <td className="font-size black">Remarks</td>
                          <td className="text-center font-size black"> <input
                            type="text"
                            name='labRemarks'
                            onChange={reported}
                            defaultValue={report?.labRemarks}
                            className='medicalInput border-0 w-100 ' /></td>
                        </tr>
                      </> : <>
                        <tr>
                          <td className="font-size black">Status</td>
                          <td className="text-center font-size black col-md-8">
                            {report?.labStatus}</td>
                        </tr>
                        <tr>
                          <td className="font-size black">Remarks</td>
                          <td className="text-center font-size black">{report?.labRemarks}</td>
                        </tr>

                      </>}

                  </tbody>
                  <tbody >

                    <tr>
                      <td className="text-left font-weight-bolder font-size black" colSpan={3} style={{ backgroundColor: "#2E64FE" }}>X-RAY
                        INVESTIGATION
                      </td>
                    </tr>
                    {permissionC.edit  && permissionF?.roleId  !== 1 ? <>
                      <tr>
                        <td className="font-size black">Status</td>
                        <td className="text-center font-size black col-md-8">
                          <select className='medicalInput border-0 w-100 ' name='xrayStatus' onChange={reported} >
                            <option>SELECT</option>
                            <option value="FIT">Fit</option>
                            <option value="UNFIT">Unfit</option>
                            <option value="HELD_UP">Heldup</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-size black">Remarks</td>
                        <td className="text-center font-size black">
                        <input
                            type="text"
                            name='xrayRemarks'
                            onChange={reported}
                            defaultValue={report?.xrayRemarks}
                            className='medicalInput border-0 w-100 ' />
                           </td>
                      </tr>
                    </> : <>
                    <tr>
                          <td className="font-size black">Status</td>
                          <td className="text-center font-size black col-md-8">
                            {report?.xrayStatus}</td>
                        </tr>
                        <tr>
                          <td className="font-size black">Remarks</td>
                          <td className="text-center font-size black">{report?.xrayRemarks}</td>
                        </tr>
                    </>}

                  </tbody>
                  <tbody>
                      {permissionF.edit ?
                      <>
                      <tr>
                      <td className="text-left font-weight-bolder font-size black col-md-8" colSpan={3} style={{ backgroundColor: "#FF0040" }}>   FINAL STATUS
                      </td>
                    </tr>
                    <tr>
                      <td className="font-size black">Status</td>
                      <td className="text-center font-size black col-md-8">
                        <select className='medicalInput border-0 w-100 ' name="status"  onChange={reported} >
                          <option>SELECT</option>
                          <option value="FIT">Fit</option>
                          <option value="UNFIT">Unfit</option>
                          <option value="HELD_UP">Heldup</option>
                        </select>
                      </td>

                    </tr>
                    <tr>
                      <td className="font-size black">Remarks</td>
                      <td className="text-center font-size black">
                         <input
                            type="text"
                            name='remark'
                            onChange={reported}
                            defaultValue={report?.remark}
                            className='medicalInput border-0 w-100 ' />
  
                      </td>
                    </tr>
                      </>:
                      ''
                      
                      
                      }
                    
                  </tbody>
                </table>
              </div>
              
              {
                                action === 'print-view' ?
                                    <div className={`modal fade fadeIn show`} role="dialog"
                                         style={{ display: 'block' }} data-backdrop="false" tabIndex={-1}>
                                        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                            <div className="modal-content ">
                                                <div className="modal-body p-0 scroll-80">
                                                    <UserProfileDetail patient={patient} />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-sm btn-secondary"
                                                            onClick={() => setAction('none')}>Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientReportContent;
