import React, {useEffect, useState} from 'react';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FormDate(props:any) {
    const { label, requiredLabel, errorMessage, onChange, column, id, defaultValue,value,name,format, ...inputProps } = props;
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date:any) => {
        console.log(date)
        setSelectedDate(date);
        onChange({target:{name:name,value:date}});
    };

    useEffect(() => {
        if (value && value instanceof String){
            console.log(value)
            const [day, month, year] = value.split('/');
            const dateObject:any = new Date(`${year}-${month}-${day}`);
            setSelectedDate(dateObject);
        }

    }, [value]);

    return (
        <div className={"mb-1 col-md-" + column}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <label htmlFor={id}>{label}{inputProps.required ? <span style={{ color: "red", marginLeft: "5px" }}>*</span> : ''} </label>
            </div>
            <ReactDatePicker
                key={`date-${id}`}
                id={id}
                name={name}
                wrapperClassName={'w-100'}
                className="form-control"
                selected={selectedDate}
                isClearable
                onChange={handleDateChange}
                timeIntervals={30}
                dateFormat={format?format: "dd/MM/yyyy"}
                placeholderText={format?format: "dd/MM/yyyy"}/>
            <div className="invalid-feedback">{errorMessage}</div>
        </div>
    );
}

export default FormDate;