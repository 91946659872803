import React, {useEffect, useState} from 'react';
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import {MODEL} from "../../utils/FormFields";
import PatientService from "../../services/PatientService";
import RefValuesService from "../../services/RefValuesService";
import moment from "moment";
import ReactToPrint from "react-to-print";
import AuthService from "../../services/AuthService";
import FuncUtil from "../../utils/FuncUtil";

function BlankReportContent(props: any) {
    const configuration = AuthService.getConfiguration();
    const [loaded, setLoaded] = useState(false);
    const [patient, setPatient] = useState(props?.patient);

    const [report, setReport] = useState(MODEL.REPORT);
    const [refValue, setRefValue] = useState(MODEL.REF_VALUE);
    const pageStyle = `
      @page {
        size: auto;
        margin: 0;
      }
      @media print {
        html, body {
            width: 210mm;  /* Width of A4 paper */
            height: 297mm; /* Height of A4 paper */
            margin: 0;     /* Remove any default margins */
            box-shadow: none; /* Remove any box shadows */
            background-color: #FFF; /* Set a white background if needed */
        }
        body {
          margin: 0;
        }
      }
    `;

    useEffect(() => {
      console.log(document.getElementById('patient-report-print-content'));
        PatientService.findById(patient.id).then(response => {
            setPatient(response.data);
            setReport(response.data.report);
        })
        RefValuesService.find().then(response => {
            setRefValue(response.data);
        })
    }, [])
    console.log("report", report);


    return (
        <div className="card mb-0">
            <div className="card-content collapse show">
                <div className="card-body">
                    <div className="row">
                        <div className="col-8">
                            <h3 className="font-size mb-2">Blank Test Report of {patient.fullName}</h3>
                        </div>
                        <div className="col-4">
                            <ReactToPrint

                                documentTitle={patient.passportNo}
                                content={() => document.getElementById('patient-report-print-content')}
                                trigger={() => <button className="btn btn-info pull-right">Print</button>}/>
                        </div>
                    </div>
                    <div className={'printable mx-3 my-2'} id={'patient-report-print-content'}>
                        <div className={'row'}>
                            <div className="col-12 text-center">
                                <div className="px-0 row  list-unstyled black">
                                    <div className='col-2  py-2 border-right-0 '>
                                        <img src={configuration.reportCompanyLogo} className="mb-0" alt=''
                                             height={100}/>
                                    </div>
                                    <div className='col-10 py-2 '>
                                        <div>
                                            <h3 className='text-bold-600'>{configuration.businessName}</h3>
                                            <h4>Address : {configuration.invoiceAddress}</h4>
                                        </div>
                                        <div className='d-flex justify-content-around'>
                                            <p>CONTACT NO : {configuration.reportContactNumber} </p>
                                            <p>Website :{configuration.websiteUrl} </p>
                                            <p>Report Download : {configuration.reportUrl}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-12 text-center">
                                <h4 className={'font-weight-bolder'}>MEDICAL TEST REPORT</h4>
                            </div>
                        </div>
                        <table className="table-bordered table report-header">
                            <tbody>
                            <tr className={'text-center'}>
                                <td className="black width-20-per text-center">
                                    <span>CLIENT ID : {patient.regNo}</span>
                                    <Barcode value={patient.regNo ? patient.regNo : 'NA'} marginTop={10}
                                             displayValue={false} width={2} height={50}/>
                                    <br/>
                                    <span>REG DATE : {moment(FuncUtil.toDateTime(patient?.createdDate)).format('DD-MM-YYYY')}
                    </span>
                                </td>
                                <td className="black width-20-per text-center">
                                    <img src={patient?.photo} alt="" style={{height: 120, maxWidth: 150}}/>
                                </td>
                                <td className="black width-20-per text-center">
                                    <img src={patient?.fingerPrint} alt="" style={{height: 120, maxWidth: 150}}/>
                                </td>
                                <td className="black width-20-per text-center">
                                    <img src={report?.xrayImage} alt="" style={{height: 120, maxWidth: 150}}/>
                                </td>
                                <td className="black width-20-per text-center">
                                    <QRCode size={100} className={'qr-image mt-1'}
                                            value={patient?.qr ? patient.qr : ''}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center font-weight-bolder font-size black">DATE
                                    : {moment(FuncUtil.toDateTime(report?.modifiedDate)).format('DD-MM-YYYY hh:mm A')}</td>
                                <td className="text-center font-weight-bolder font-size black">PHOTO</td>
                                <td className="text-center font-weight-bolder font-size black">THUMB PRINT</td>
                                <td className="text-center font-weight-bolder font-size black">X –RAY IMAGE</td>
                                <td className="text-center font-weight-bolder font-size black">QR CODE</td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="table-bordered table">
                            <tbody>
                            <tr>
                                <td className="font-size black">NAME</td>
                                <td className="font-size black">{patient.fullName}</td>
                                <td className="font-size black">CLIENT ID</td>
                                <td className="font-size black">{patient.regNo}</td>
                            </tr>
                            <tr>
                                <td className="font-size black">GENDER</td>
                                <td className="font-size black">{patient.gender}</td>
                                <td className="font-size black">PROFESSION</td>
                                <td className="font-size black">{patient.profession}</td>
                            </tr>
                            <tr>
                                <td className="font-size black">DATE OF BIRTH</td>
                                <td className="font-size black">{moment(patient.dateOfBirth).format('DD-MM-YYYY')}</td>
                                <td className="font-size black">TRAVELING TO</td>
                                <td className="font-size black">{patient.travelingTo}</td>
                            </tr>
                            <tr>
                                <td className="font-size black">PASSPORT NO.</td>
                                <td className="font-size black">{patient.passportNo}</td>
                                <td className="font-size black">CONTACT NUMBER</td>
                                <td className="font-size black">{patient.mobile}</td>
                            </tr>
                            <tr>
                                <td className="font-size black">NATIONALITY</td>
                                <td className="font-size black">Bangladeshi</td>
                                <td className="font-size black">NAME OF AGENCY / SUB AGENT</td>
                                <td className="font-size black">{patient.agentOrAgencyName}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="row">
                          
                            <div className="col-md-6 pr-0">
                                <table className="table-bordered table ">
                                    <tbody>
                                    <tr>
                                        <td className="text-center font-weight-bolder font-size black"
                                            colSpan={5}>MEDICAL
                                            EXAMINATION
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black">TYPE OF
                                            EXAMINATION
                                        </td>
                                        <td className="text-center font-weight-bolder font-size black"
                                            colSpan={2}>RESULTS
                                        </td>
                                        <td className="text-center font-weight-bolder font-size black" colSpan={2}>REF.
                                            VALUE
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2} className="font-size black">VISUAL ACUITY</td>
                                        <td colSpan={2} className="text-center font-size black">UNAIDED</td>
                                        <td colSpan={2} className="text-center font-size black">AIDED</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center font-size black">RIGHT</td>
                                        <td className="text-center font-size black">LEFT</td>
                                        <td className="text-center font-size black">RIGHT</td>
                                        <td className="text-center font-size black">LEFT</td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">DISTANT</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">NEAR</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">CLEAR VISION</td>
                                        <td colSpan={4} className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black" rowSpan={2}>HEARING (EAR)</td>
                                        <td className="text-center font-size black">L</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-center font-size black">R</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-size black font-weight-bolder" colSpan={5}>
                                            SYSTEMIC EXAM: CARDIO - VASCULAR
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">B.P</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HEART</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2} rowSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">PULSE</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black" colSpan={5}>
                                            RESPIRATORY EXAM
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">LUNGS</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">GASTROINTESTINAL ABDOMEN</td>
                                        <td className="text-center font-size black"
                                            colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black"
                                            colSpan={5}>OTHERS
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HEIGHT (CM)</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">WEIGHT (KG)</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HERNIA</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">VARICOSEVEINS</td>
                                        <td className="text-center font-size black"
                                            colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">DEFORMITIES</td>
                                        <td className="text-center font-size black"
                                            colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">SKIN</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">C.N.S.</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">EXTREMITIES</td>
                                        <td className="text-center font-size black"
                                            colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">PSYCHIATRY</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black"
                                            colSpan={5}>VENEREAL
                                            DISEASES
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">SYMPTOMS</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black" colSpan={5}>X-RAY
                                            INVESTIGATION
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">CHEST X-RAY</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">ECG</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">Remarks</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black" colSpan={5}>DRUG
                                            TEST
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">THC</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">MOP</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">AMP</td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                        <td className="text-center font-size black" colSpan={2}></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div>
                                </div>
                            </div>
                            <div className="col-md-6 pl-0">
                                <table className="table-bordered table ">
                                    <tbody>
                                    <tr>
                                        <td className="text-center font-weight-bolder font-size black"
                                            colSpan={3}>LABORATORY
                                            INVESTIGATION
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center font-weight-bolder font-size black">TYPE OF
                                            EXAMINATION
                                        </td>
                                        <td className="text-center font-weight-bolder font-size black"
                                            colSpan={1}>RESULTS
                                        </td>
                                        <td className="text-center font-weight-bolder font-size black">REF. VALUE</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black" colSpan={3}>URINE
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">SUGAR</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">ALBUMIN</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">PREGNANCY TEST</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">OTHERS</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black" colSpan={3}>STOOL
                                            R/E (IF
                                            REQUIRED)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HELMINTHS</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">GIARDIA</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">CULTURE</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black"
                                            colSpan={3}> HEMATOLOGY
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">MALARIA</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">MICROFILARIA</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">BLOOD GROUP</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HEMOGLOBIN</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black"> LEISHMANIASIS</td>
                                        <td className="text-center font-size black">{report?.haemoglobin}</td>
                                        <td className="text-center font-size black">{refValue.haemoglobin}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">ESR</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black"
                                            colSpan={3}>BLOOD-BIOCHEMISTRY
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">R.B.S.</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">S. CREATININE</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">T. BILIRUBIN</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">SGPT</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">SGOT</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">ALP</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">UREA</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left font-weight-bolder font-size black"
                                            colSpan={3}>IMMUNOLOGY & SEROLOGY
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">ANTI - HCV</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">TPHA (IF VDRL POSITIVE)</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">VDRL</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HIV (I & II)</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">HB<small>S</small>Ag</td>
                                        <td className="text-center font-size black"></td>
                                        <td className="text-center font-size black"></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-10 pl-0 signature-container">
                                <div className="text-center font-weight-bolder font-size black signature-item">
                                    <hr className="custom-hr"/>
                                    RADIOLOGIST SIGNATURE
                                </div>
                                <div className="text-center font-weight-bolder font-size black signature-item">
                                    <hr className="custom-hr"/>
                                    PATHOLOGIST SIGNATURE
                                </div>
                                <div className="text-center font-weight-bolder font-size black signature-item">
                                    <hr className="custom-hr"/>
                                    GENERAL PHYSICIAN SIGNATURE
                                </div>
                            </div>
                        </div>

                        {report?.status ?
                            <table className="table-bordered table ">
                                <tbody>
                                <tr>
                                    <td className="font-size black">
                                        Mentioned above is the medical report for <b> {patient.fullName}</b>, Who
                                        is <span className={"font-weight-bolder"}>{report.status}</span> for the above
                                        mentioned job according to the Medical criteria.
                                    </td>
                                    <td className="font-weight-bolder font-size black" style={{whiteSpace: "pre-line"}}>
                                        {report.status != null ?
                                            <img src={configuration.reportDoctorSeal} className={'width-100'}/> : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-size black">REMARK : {patient.remark}</td>
                                    <td className="font-size black">FINAL CHECKED BY : {patient.lastModifiedBy}</td>
                                </tr>
                                </tbody>
                            </table> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlankReportContent;
