import React from 'react';

const colorArray = [
    '#3498db', // Blue
    '#2ecc71', // Green
    '#f39c12', // Orange
    '#9b59b6', // Purple
    '#2c3e50', // Dark Gray
    '#1abc9c', // Turquoise
    '#d35400', // Pumpkin
    '#27ae60', // Emerald
    '#c0392b', // Dark Red
    '#e74c3c', // Red
];
class ActionDropdown extends React.Component<{ label: string, actions: any[], styles: string, data: any }> {
    render() {
        let {label, actions, styles, data} = this.props;
        return (
            <div className="dropdown">
                {/* btn btn-primary dropdown-toggle */}
                <button
                    className={styles}
                    type="button"
                    id="actionDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {label}
                </button>
                <div className="dropdown-menu" aria-labelledby="actionDropdown">
                    {actions.map((action: any, index: number) => (
                        <a key={index} className={`dropdown-item`}
                           style={{backgroundColor:colorArray[index % colorArray.length],color:'white'}}
                           data-record={JSON.stringify(data)}
                           onClick={action.onClick}>
                            {action.label}
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}

export default ActionDropdown;
