    import React, {useEffect, useState} from 'react';
    import Barcode from "react-barcode";
    import QRCode from "react-qr-code";
    import {MODEL} from "../../utils/FormFields";
    import PatientService from "../../services/PatientService";
    import RefValuesService from "../../services/RefValuesService";
    import moment from "moment";
    import ReactToPrint from "react-to-print";
    import AuthService from "../../services/AuthService";
    import FuncUtil from "../../utils/FuncUtil";
    import { useRef } from 'react';
    import generatePDF, { Resolution, Margin } from 'react-to-pdf';

    function PatientReportContent1(props: any) {
        const configuration = AuthService.getConfiguration();
        const [loaded, setLoaded] = useState(false);
        const [patient, setPatient] = useState(props?.patient);

        const [report, setReport] = useState(MODEL.REPORT);
        const [refValue, setRefValue] = useState(MODEL.REF_VALUE);
        const pageStyle = `
        @page {
            size: auto;
            margin: 0;
        }
        @media print {
            html, body {
            width: 200mm;
            height: 297mm;
            }
            body {
            margin: 0;
            }
        }
        `;

        useEffect(() => {
            PatientService.findById(patient.id).then(response => {
                setPatient(response.data);
                setReport(response.data.report);
            })
            RefValuesService.find().then(response => {
                setRefValue(response.data);
            })
        }, [])
        
        const targetRef = useRef(null);


        return (
            <div className="card mb-0">
                <div className="card-content collapse show">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="font-size mb-2">Medical Test Report of {patient.fullName}</h3>
                            </div>
                            <div className="col-4">
                                <button type="button"
                                        className="btn btn-info pull-right mr-2"
                                        onClick={() => generatePDF(targetRef, 
                                            {
                                                filename: `Medical-Report-Of-${patient.passportNo}-${patient.fullName}.pdf`,
                                                resolution: Resolution.LOW,
                                                page: {
                                                    margin: Margin.SMALL,
                                                    format: 'tabloid'
                                                },
                                                overrides: {
                                                    pdf: {
                                                       compress: true
                                                    }
                                                }
                                            })
                                        }>Download PDF</button>

                                <ReactToPrint
                                    documentTitle={patient.passportNo}
                                    pageStyle="@media print {
                                        html, body {
                                            size: 46mm 62mm;
                                            margin: 0px !important;
                                            margin-left: 5px !important;
                                            padding: 0px !important;
                                            padding-top: -60px !important;
                                          }
                                      }"
                                    content={() => document.getElementById('patient-medical-report-print-content')}
                                    trigger={() => <button className="btn btn-info pull-right mr-2">Print</button>}/>
                            </div>
                        </div>
                        <div ref={targetRef}  className={'printable mx-3 my-2'} id={'patient-medical-report-print-content'}>
                        <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                                <div className="col-12 text-center">
                                    <div className="px-0 row  list-unstyled black">
                                        <div className='col-12  py-2 border-right-0 '>
                                            <img src={configuration.reportCompanyLogo} className="mb-0" alt=''
                                                height={100}/>
                                        </div>
                                        <div className='col-12 py-2 '>
                                            <div>
                                                <h3 className='text-bold-600'>{configuration.businessName}</h3>
                                                <h4>Address : {configuration.invoiceAddress}</h4>
                                            </div>
                                            <div className='d-flex justify-content-around'>
                                                <p>CONTACT NO : {configuration.reportContactNumber} </p>
                                                <p>Website :{configuration.websiteUrl} </p>
                                                <p>Report Download : {configuration.reportUrl}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                                <div className="col-md-12 text-center" style={{paddingLeft:'0px !important'}}>
                                    <h4 className={'font-weight-bolder'}>MEDICAL TEST REPORT</h4>
                                </div>
                            <table className="table-bordered table report-header">
                                <tbody>
                                <tr className={'text-center'}>
                                    <td colSpan={2} className="black width-20-per text-center">
                                        <span>CLIENT ID : {patient.regNo}</span>
                                        <Barcode value={patient.regNo ? patient.regNo : 'NA'} marginTop={10}
                                                displayValue={false} width={2} height={50}/>
                                        <br/>
                                        <span>REG DATE : {moment(FuncUtil.toDateTime(patient?.createdDate)).format('DD-MM-YYYY')}
                        </span>
                                    </td>
                                    <td colSpan={1} className="black width-20-per text-center">
                                        <img src={patient?.photo} alt="" style={{height: 120, maxWidth: 150}}/>
                                    </td>
                                    <td colSpan={1} className="black width-20-per text-center">
                                        <img src={patient?.fingerPrint} alt="" style={{height: 120, maxWidth: 150}}/>
                                    </td>
                                    <td colSpan={1} className="black width-20-per text-center">
                                        <img src={report?.xrayImage} alt="" style={{height: 120, maxWidth: 150}}/>
                                    </td>
                                    <td colSpan={1} className="black width-20-per text-center">
                                        <QRCode size={100} className={'qr-image mt-1'}
                                                value={patient?.qr ? patient.qr : ''}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="text-center font-weight-bolder font-size black">DATE
                                        : {moment(FuncUtil.toDateTime(report?.modifiedDate)).format('DD-MM-YYYY hh:mm A')}</td>
                                    <td colSpan={1} className="text-center font-weight-bolder font-size black">PHOTO</td>
                                    <td colSpan={1} className="text-center font-weight-bolder font-size black">THUMB PRINT</td>
                                    <td colSpan={1} className="text-center font-weight-bolder font-size black">X –RAY IMAGE</td>
                                    <td colSpan={1} className="text-center font-weight-bolder font-size black">QR CODE</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                            <table className="table-bordered table">
                                <tbody>
                                <tr>
                                    <td colSpan={1} className="font-size black">NAME</td>
                                    <td colSpan={1} className="font-size black">{patient.fullName}</td>
                                    <td colSpan={3} className="font-size black">CLIENT ID</td>
                                    <td colSpan={1} className="font-size black">{patient.regNo}</td>
                                </tr>
                                <tr>
                                    <td colSpan={1} className="font-size black">GENDER</td>
                                    <td colSpan={1} className="font-size black">{patient.gender}</td>
                                    <td colSpan={3} className="font-size black">PROFESSION</td>
                                    <td colSpan={1} className="font-size black">{patient.profession}</td>
                                </tr>
                                <tr>
                                    <td colSpan={1} className="font-size black">DATE OF BIRTH</td>
                                    <td colSpan={1} className="font-size black">{moment(patient.dateOfBirth).format('DD-MM-YYYY')}</td>
                                    <td colSpan={3} className="font-size black">TRAVELING TO</td>
                                    <td colSpan={1} className="font-size black">{patient.travelingTo}</td>
                                </tr>
                                <tr>
                                    <td colSpan={1} className="font-size black">PASSPORT NO.</td>
                                    <td colSpan={1} className="font-size black">{patient.passportNo}</td>
                                    <td colSpan={3} className="font-size black">CONTACT NUMBER</td>
                                    <td colSpan={1} className="font-size black">{patient.mobile}</td>
                                </tr>
                                <tr>
                                    <td colSpan={1} className="font-size black">NATIONALITY</td>
                                    <td colSpan={1} className="font-size black">Bangladeshi</td>
                                    <td colSpan={3} className="font-size black">NAME OF AGENCY / SUB AGENT</td>
                                    <td colSpan={1} className="font-size black">{patient.agentOrAgencyName}</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>

                            <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                            <div className="col-md-6" style={{paddingLeft:'0px'}}>
                                    <table className="table-bordered table ">
                                        <tbody>
                                        <tr>
                                            <td className="text-center font-weight-bolder font-size black"
                                                colSpan={6}>MEDICAL
                                                EXAMINATION
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black"
                                            colSpan={2}
                                            >TYPE OF
                                                EXAMINATION
                                            </td>
                                            <td className="text-center font-weight-bolder font-size black"
                                                colSpan={2}>RESULTS
                                            </td>
                                            <td className="text-center font-weight-bolder font-size black" colSpan={2}>REF.
                                                VALUE
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} rowSpan={2} className="font-size black">VISUAL ACUITY</td>
                                            <td colSpan={2} className="text-center font-size black">UNAIDED</td>
                                            <td colSpan={2} className="text-center font-size black">AIDED</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center font-size black">RIGHT</td>
                                            <td className="text-center font-size black">LEFT</td>
                                            <td className="text-center font-size black">RIGHT</td>
                                            <td className="text-center font-size black">LEFT</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">DISTANT</td>
                                            <td className="text-center font-size black">{report?.distantUnaidedRight}</td>
                                            <td className="text-center font-size black">{report?.distantUnaidedLeft}</td>
                                            <td className="text-center font-size black">{report?.distantAidedRight}</td>
                                            <td className="text-center font-size black">{report?.distantAidedLeft}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">NEAR</td>
                                            <td className="text-center font-size black">{report?.nearUnaidedRight}</td>
                                            <td className="text-center font-size black">{report?.nearUnaidedLeft}</td>
                                            <td className="text-center font-size black">{report?.nearAidedRight}</td>
                                            <td className="text-center font-size black">{report?.nearAidedLeft}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">CLEAR VISION</td>
                                            <td colSpan={4}
                                                className="text-center font-size black">{report?.clearVision}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-size black" colSpan={2} rowSpan={2}>HEARING (EAR)</td>
                                            <td className="text-center font-size black">L</td>
                                            <td className="text-center font-size black">{report?.earLeft}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.earLeft}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center font-size black">R</td>
                                            <td className="text-center font-size black">{report?.earRight}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.earRight}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-size black font-weight-bolder" colSpan={6}>
                                                SYSTEMIC EXAM: CARDIO - VASCULAR
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">B.P</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{report?.bloodPressure}</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{refValue.bloodPressure}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HEART</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.heart}</td>
                                            <td className="text-center font-size black" colSpan={2}
                                                rowSpan={2}>{refValue.heart}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">PULSE</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.pulse}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black" colSpan={6}>
                                                RESPIRATORY EXAM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">LUNGS</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.lungs}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.lungs}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">GASTROINTESTINAL ABDOMEN</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{report?.gastrointestinalAbdomen}</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{refValue.gastrointestinalAbdomen}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black"
                                                colSpan={6}>OTHERS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HEIGHT (CM)</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.height}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.height}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">WEIGHT (KG)</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.weight}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.weight}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HERNIA</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.hernia}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.hernia}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">VARICOSEVEINS</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{report?.varicoseVeins}</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{refValue.varicoseVeins}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">DEFORMITIES</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{report?.deformities}</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{refValue.deformities}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">SKIN</td>
                                            <td colSpan={4} className="text-center font-size black">{report?.skin}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">C.N.S.</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.cns}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.cns}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">EXTREMITIES</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{report?.extremities}</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{refValue.extremities}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">PSYCHIATRY</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{report?.psychiatry}</td>
                                            <td className="text-center font-size black"
                                                colSpan={2}>{refValue.psychiatry}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black"
                                                colSpan={6}>VENEREAL
                                                DISEASES
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">SYMPTOMS</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.symptoms}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.symptoms}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black" colSpan={6}>X-RAY
                                                INVESTIGATION
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">CHEST X-RAY</td>
                                            <td className="text-center font-size black" colSpan={4}>{report?.chestXray}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">ECG</td>
                                            <td className="text-center font-size black" colSpan={4}>{report?.ecg}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">Remarks</td>
                                            <td className="text-center font-size black"
                                                colSpan={4}>{report?.xrayImageRemarks}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black" colSpan={6}>DRUG
                                                TEST
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">THC</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.thc}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.thc}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">MOP</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.mop}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.mop}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">AMP</td>
                                            <td className="text-center font-size black" colSpan={2}>{report?.amp}</td>
                                            <td className="text-center font-size black" colSpan={2}>{refValue.amp}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            
                                <div className="col-md-6" style={{paddingRight:'0px'}}>
                                    <table className="table-bordered table ">
                                        <tbody>
                                        <tr>
                                            <td className="text-center font-weight-bolder font-size black"
                                                colSpan={6}>LABORATORY
                                                INVESTIGATION
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="text-center font-weight-bolder font-size black">TYPE OF
                                                EXAMINATION
                                            </td>
                                            <td className="text-center font-weight-bolder font-size black"
                                                colSpan={2}>RESULTS
                                            </td>
                                            <td colSpan={2} className="text-center font-weight-bolder font-size black">REF. VALUE</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black" colSpan={6}>URINE
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">SUGAR</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.sugar}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.sugar}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">ALBUMIN</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.albumin}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.albumin}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.urineBilharziasis}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.urineBilharziasis}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">PREGNANCY TEST</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.pregnancy}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.pregnancy}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">OTHERS</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.others}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.others}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black" colSpan={6}>STOOL
                                                R/E (IF
                                                REQUIRED)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HELMINTHS</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.helminths}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.helminths}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">GIARDIA</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.giardia}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.giardia}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">BILHARZIASIS (IF ENDEMIC)</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.stoolBilharziasis}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.stoolBilharziasis}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">CULTURE</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.culture}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.culture}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black"
                                                colSpan={6}> HEMATOLOGY
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">MALARIA</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.malaria}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.malaria}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">MICROFILARIA</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.microfilaria}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.microfilaria}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">BLOOD GROUP</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.bloodGroup}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.bloodGroup}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HEMOGLOBIN</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.haemoglobin}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.haemoglobin}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black"> LEISHMANIASIS</td>
                                            
                                            <td colSpan={2} className="text-center font-size black">{report?.leishmainasis}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.leishmainasis}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">ESR</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.esr}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.esr}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black"
                                                colSpan={6}>BLOOD-BIOCHEMISTRY
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">R.B.S.</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.rbs}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.rbs}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">S. CREATININE</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.creatinine}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.creatinine}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">S. BILIRUBIN</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.tbil}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.tbil}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">SGPT</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.sgpt}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.sgpt}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">SGOT</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.sgot}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.sgot}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">ALP</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.alp}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.alp}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">UREA</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.urea}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.urea}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bolder font-size black"
                                                colSpan={6}>IMMUNOLOGY & SEROLOGY
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">ANTI - HCV</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.antiHcv}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.antiHcv}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">TPHA (IF VDRL POSITIVE)</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.tpha}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.tpha}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">VDRL</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.vdrl}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.vdrl}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HIV (I & II)</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.hiv}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.hiv}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="font-size black">HB<small>S</small>Ag</td>
                                            <td colSpan={2} className="text-center font-size black">{report?.hbsag}</td>
                                            <td colSpan={2} className="text-center font-size black">{refValue.hbsag}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {report?.status ?
                                <table className="table-bordered table ">
                                    <tbody>
                                    <tr>
                                        <td className="font-size black">
                                            Mentioned above is the medical report for <b> {patient.fullName}</b>, Who
                                            is <span className={"font-weight-bolder"}>{report.status}</span> for the above
                                            mentioned job according to the Medical criteria.
                                        </td>
                                        <td className="font-weight-bolder font-size black" style={{whiteSpace: "pre-line"}}>
                                            {report.status != null ?
                                                <img src={configuration.reportDoctorSeal} className={'width-100'}/> : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-size black">REMARK : {report.remark}</td>
                                        <td className="font-size black">FINAL CHECKED BY : {patient.lastModifiedBy}</td>
                                    </tr>
                                    </tbody>
                                </table> : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    export default PatientReportContent1;
