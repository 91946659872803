  import React, { useEffect, useState, useRef } from 'react';
  import http from "../../../config/httpConfig";
  import moment from "moment";
  import FileUtil from "../../../utils/CsvUtil";
  import CsvUtil from "../../../utils/CsvUtil";
  import PdfUtil from "../../../utils/PdfUtil";
  import FuncUtil from "../../../utils/FuncUtil";
  import { useReactToPrint } from "react-to-print";
  import formatDateTime from '../../../model/formatDateTime';
  import { data, input } from '@tensorflow/tfjs-node';
  import FormField from '../../../components/ui/FormField';
  import { FORM } from '../../../utils/FormFields';
  import { render } from '@testing-library/react';
  import BillService from '../../../services/BillService';
  import NewForm from '../../../components/ui/NewFormSelect';
  import {API_ROUTES, BASE_URL} from '../../../utils/constants';
  import {toast} from "react-toastify";



  interface Page {
    pageNumber: number,
    pageSize: number,
    text: string,
    from: string,
    to: string
    total: number,
    data: []
  }

  function ActualBillTable(props: any) {
    const [dataUpdated, setDataUpdated] = useState({});

    const dateFormat = 'DD/MM/yyyy';
    const {columns, endpoint, pagesSizes, actionButtons, dateFilter } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isFailed, setIsFailed] = useState(false);
    const [message, setMessage] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [searchParams, setSearchParams] = useState({
      from:moment(new Date()).format(dateFormat),
      to:moment(new Date()).format(dateFormat),
      column: "id",
      sort: "DESC",
    });
    const [pagination, setPagination] = useState({
      page: 0,
      size: 100,
      totalElements: 0,
      totalPages: 0,
      numberOfElements:0
    });
    const rangeOptions = [{ value: 'today', text: `Today`},
      { value: 'yesterday', text: `Yesterday`},
      { value: 'week', text: `This Week`},
      { value: 'month', text: `This Month`},
      { value: 'year', text: `This Year`}];

    useEffect(() => {
      fetchActualBills();
    }, [pagination.page, pagination.size]);

    const fetchActualBills = () => {
      setIsLoading(true);
      setIsFailed(false);
      BillService.searchActualBills(searchParams,pagination).then(response => {
        const {content,numberOfElements,totalElements,totalPages}= response.data;
        setIsLoading(false);
        setIsFailed(false);
        setPagination(prevState => ({ ...prevState, totalPages,totalElements,numberOfElements}));
        setFilteredData(content);
      }).catch(reason => {
        setIsFailed(true);
        setMessage(reason.code);
        setIsLoading(false);
      })
    };

    const handleSearch = (e:any) => {
      e.preventDefault();
      // Implement your search logic based on user input
      // Update the searchParams state accordingly
      setPagination({ ...pagination, page: 0 }); // Reset page to 0 when searching
      fetchActualBills();
    };

    const handlePageChange = (newPage:any) => {
      setPagination({ ...pagination, page: newPage });
    };
    const handlePageSize = (e:any) => {
      const {value} = e.target;
      setPagination({ ...pagination, size: Number(value) });
    };

    const handleInputChange = (e:any) => {
      const {name,value} = e.target;
      if (value instanceof Date){
        const date = moment(new Date(value)).format(dateFormat);
        setSearchParams(prevState => ({...prevState,[name]:date}));
      }else{
        setSearchParams(prevState => ({...prevState,[name]:value}));
      }
      setPagination({ ...pagination, page: 0 });
    };
    const handleRangeChange = (e: any) => {
      const {value} = e.target;
      const today = new Date();
      let from:any = null, to:any = null;
      switch (value) {
        case 'today':
          from = to = moment(today).format(dateFormat);
          break;
        case 'yesterday':
          from = to = moment(today).subtract(1, 'days').format(dateFormat);
          break;
        case 'week':
          from = moment(today).subtract(7, 'days').format(dateFormat);
          to = moment(today).format(dateFormat);
          break;
        case 'month':
          from = moment(today).subtract(30, 'days').format(dateFormat);
          to = moment(today).format(dateFormat);
          break;
        case 'year':
          from = moment(today).subtract(365, 'days').format(dateFormat);
          to = moment(today).format(dateFormat);
          break;
      }
      setSearchParams((prevState) => ({ ...prevState, from, to }));
    }

    const handleSort = (column: string) => {
      if (searchParams.column === column) {
        const sorting = searchParams.sort === 'ASC' ? 'DESC' : 'ASC';
        setSearchParams(prevState => ({...prevState,sort:sorting,column:column}));
      } else {
        setSearchParams(prevState => ({...prevState,sort:'ASC',column:column}));
      }
      setPagination({ ...pagination, page: 0 });
    }

    const generateCsvData = () => {
      return filteredData.map((record: any) => {
        delete record.createdDate;
        delete record.modifiedDate;
        return Object.keys(record).map(function (k) {
          return record[k]
        }).join('\t');
      }).join('\n');
    }
    const [values, setValues] = useState({
      id: null,
      photo: "",
      fingerPrint: null,
      passportNo: "",
      issueDate: "",
      expiredDate: "",
      visaNo: "",
      visaDate: "",
      travelingTo: "",
      presentAddress: "",
      permanentAddress: "",
      mobile: "",
      email: "",
      group: "",
      testOrPackageId: 0,
      agentOrAgencyId: 0,
      deliveryDate: "",
      gender: null,
      age: 0,
      maritalStatus: null,
      dateOfBirth: "",
      fathersName: "",
      mothersName: "",
      nationality: "",
      religion: "",
      profession: "",
      status: "ACTIVE",
      nidNumber: "",
      specialNote: "",
      fingerId: 0,
      payNow: true,
    });
    const onCopy = () => {
      navigator.clipboard.writeText(generateCsvData());
    }

    const onCsv = () => {
      filteredData.map((record: any) => {
        delete record.createdDate;
        delete record.modifiedDate;
        return record;
      })
      FileUtil.generateCsv("data.csv", filteredData, columns);
    }
    const onExcel = () => {
      filteredData.map((record: any) => {
        delete record.createdDate;
        delete record.modifiedDate;
        return record;
      })
      // CsvUtil.generateExcel('data', filteredData,"landscape");
      FileUtil.generateExcel("data", filteredData, columns);
    }
    const onPdf = () => {
      PdfUtil.downloadPdf("data", "datatable-download", "act", "");
    }

    const handlePrint = useReactToPrint({
      content: () => {
        const table = document.getElementById('datatable-print');
        const dateSelector = table?.querySelectorAll('thead td');

        dateSelector?.forEach((cell, index) => {
          console.log(cell.textContent);
        })

        return table;
      },
      pageStyle: '@page { size: landscape !important; }',
    });

    const calculateSum = (field: string) => {
      let number = 0;
      filteredData.map((record: any) => {
        number += record[field];
        return record;
      });
      return number;
    }

    const Editabble = (field: string) => {
      let number = "check";
      filteredData.map((record: any) => {
        number += record[field];
        return record;
      });
      return number;
    }

    const getValues = (props: any) => {
      const rec = props?.record;
      const dt = props?.data;
      const val = props?.e?.target?.value;
      filteredData.map((record: any) => {
        if (record['id'] === rec?.id && val && dt) {
          console.log(Number(val));
          if (dt === 'date') {
            record[dt] = new Date(val);
          } else if (dt === 'recieved') {
            record[dt] = Number(val);
          } else if (dt === 'netAmount') {
            record[dt] = Number(val);
          } else if (dt === 'commision') {
            record[dt] = Number(val);
          } else if (dt === 'due') {
            record[dt] = Number(val);
          } else if (dt === 'paid') {
            record[dt] = Number(val);
          } else {
            record[dt] = val;
          }
        }
      });
      //    const patientData = {
      //     patientId: props?.record?.id,
      //    valueType: props?.data,
      //   updateMoney: props?.e?.target?.value,

      //   }
      // BillService.updateActualBill(patientData);
      // loadData();
    }

    const onKeyDown = (e: any) => {
      let nextFocusableElement;
      console.log(e.keyCode);
      if (e.keyCode === 38) { // Arrow Up
        e.preventDefault();
        if (e.target.parentElement && e.target.parentElement.parentElement.previousSibling) {
          nextFocusableElement = e.target.parentElement.parentElement.previousSibling.childNodes[e.target.parentElement.cellIndex].childNodes[0];
        }
      }
      else if (e.keyCode === 40) { // Arrow Down
        e.preventDefault();
        
        if (e.target.parentElement && e.target.parentElement.parentElement.nextSibling) {
          nextFocusableElement = e.target.parentElement.parentElement.nextSibling.childNodes[e.target.parentElement.cellIndex].childNodes[0];
        }
      }
      else if (e.keyCode === 37) { // Arrow Left
        e.preventDefault();
        
        if (e.target.parentElement.previousSibling) {
          nextFocusableElement = e.target.parentElement.previousSibling.childNodes[0];
        }
      }
      else if (e.keyCode === 39) { // Arrow Right
        e.preventDefault();
        
        if (e.target.parentElement.nextSibling) {
          nextFocusableElement = e.target.parentElement.nextSibling.childNodes[0];
        }
      }

      // if (tableRef.current && e.target.nodeName === 'TD') {
      //   const range = document.createRange();
      //   range.selectNodeContents(e.target);
      //   const selection = window.getSelection();
      //   if (selection) {
      //     selection.removeAllRanges();
      //     selection.addRange(range);
      //   }
      // }

      if (nextFocusableElement) {
        console.log(nextFocusableElement);

        if (nextFocusableElement instanceof HTMLInputElement || nextFocusableElement instanceof HTMLTextAreaElement) {
          // For input or textarea
          console.log('Yes');
          nextFocusableElement.focus();
          nextFocusableElement.select();
        } else {
          console.log('No');
          // For other DOM elements like div, span, etc.
          const range = document.createRange();
          range.selectNodeContents(nextFocusableElement);
          const selection = window.getSelection();
          if (selection) {
            console.log(selection);
            console.log(range);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }

    };

    const onChange = (e: any) => {
      // FuncUtil.validate(e)
      setValues({ ...values, [e.target.name]: e.target.value });
      console.log(values);

    };

    // update value save 
    const updateValues = (e: any) => {
      const patientData = {
        list: filteredData
      }
      BillService.updateActualBill(patientData).then(val => {
        fetchActualBills();
        toast.success('Update Successful!',{
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
        fetchActualBills();
      }).catch(reason => {
        toast.error('Update Failed!',{
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      });
    }

    return (
      <div className={"row w-100"}>
        {actionButtons ?
          <div className={"col-md-12 mb-1 d-flex align-items-center justify-content-between"}>
            <div className={"buttons-group"}>
              <button type="button" className="btn btn-sm btn-outline-blue btn-min-width mr-1 box-shadow-1"
                onClick={onCopy}>Copy
              </button>
              <button type="button" className="btn btn-sm btn-outline-yellow btn-min-width mr-1 box-shadow-1"
                onClick={onCsv}>CSV
              </button>
              <button type="button" className="btn btn-sm btn-outline-blue-grey btn-min-width mr-1 box-shadow-1"
                onClick={onExcel}>Excel
              </button>
              <button type="button" className="btn btn-sm btn-outline-pink btn-min-width mr-1 box-shadow-1"
                onClick={onPdf}>PDF
              </button>
              <button type="button" className="btn btn-sm btn-outline-purple btn-min-width mr-1 box-shadow-1"
                onClick={handlePrint}>Print
              </button>
              <button type="button" className="btn btn-sm btn-outline-purple  btn-min-width mr-1 box-shadow-1"
                onClick={updateValues}>Update
              </button>
            </div>
          </div>: ""
        }
        <div className={`col-md-12 mb-1`}>
          <div className={'input-group pull-right'}>
            <FormField id={'from-date'} type="date" label="From" column={2} className="form-control" name="from"
                       onChange={handleInputChange} format={'dd/MM/yyyy'} value={searchParams.from}/>
            <FormField id={'to-date'} type="date" label="To" column={2} className="form-control" name="to"
                       onChange={handleInputChange} format={'dd/MM/yyyy'} value={searchParams.to}/>
            <FormField type="select" label="Period" ajax={false} column={2} className="form-control" onChange={handleRangeChange}
                       values={rangeOptions}/>
            <FormField name="testOrPackage" type="select" label="Package/Test Name" className="form-control p-1" column={2}
                       onChange={handleInputChange}
                       ajax={true}  mapping={{path: API_ROUTES.PACKAGE_AND_TEST_ALL, value: "name", text: "name"}}/>

            <FormField name="agentOrAgency" type="select" label="Agent/Agency Name" className="form-control" column={2}
                       onChange={handleInputChange}
                       ajax={true}  mapping={{path: API_ROUTES.AGENT_AND_AGENCY_ALL, value: "fullName", text: "fullName"}}/>

            <FormField name="abbreviation" type="select" label="Abbreviation" className="form-control" column={2}
                       onChange={handleInputChange}
                       ajax={true}  mapping={{ path: API_ROUTES.PACKAGE_AND_TEST_ALL, value: "abbreviation", text: "abbreviation"}}/>
          </div>
        </div>
        <div className={`col-md-12 mb-1`}>
          <div className={'input-group pull-right col-7'}>
            <input type="text" className="form-control round box-shadow-1"
                   placeholder={props.searchPlaceholder ? props.searchPlaceholder : 'Type Here to Search'}
                   aria-label="Amount (to the nearest dollar)" name="text" onChange={handleInputChange} />
            <button className="btn btn-info  box-shadow-1" onClick={handleSearch}><i className="ft-refresh-cw"></i> Filter</button>
          </div>
        </div>
        <div className={"col-md-12  w-80 pr-0"}>
          <div className={`table-container  ${filteredData?.length && 'responsiveTable'} `}>
            <table className={`table table-sm   table-hover table-striped table-bordered sourced-data`}
              id={"datatable"}
              style={{ height: "60vh", overflowY: "auto" }}
            >

              <thead className="bg-primary white sticky-header">
                <tr>
                  {columns.map((column: any, index: number) => (
                    <th key={index}
                      className={column.class ? column.class.replace('text-right', 'text-center').replace('text-left', 'text-center') : ''}
                      style={{ position: "sticky", top: 0, zIndex: "0", padding: ".5rem 0", fontSize:"12px", textAlign:"left", width:"auto" }}
                    >
                    {column.name.trim()} 
                    {column.sort ? <i className="ft-shuffle pull-right" onClick={() => handleSort(column.data)}></i> : ''}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((record: any, index) => (
                  <tr key={index} id='row-hover'>

                    {columns.map((column: any, cellNumber: number) => (

                      column["render"] ?

                        <td style={{padding: "0rem 1px",fontSize:"12px", textAlign:"left"}} className={column.class}>{column.render(record)}
                        </td> :
                        <td style={{padding: "0rem 1px",fontSize:"12px", textAlign:"left"}} key={Math.random()} className={column.class} >
                          {
                            column.data === 'index' ?
                              index + 1 : (column.currency ?
                                (
                                  <>
                                    <input
                                    type='text'
                                      onKeyDown={onKeyDown}
                                      onFocus={(e) => e.target.select()}
                                      onBlur={(e) => {
                                        clearTimeout(record[column.data].timer)
                                        const timer = setTimeout(() => getValues({ e, record, data: column.data }));

                                      }}
                                      style={{ width:'55px' , fontSize:"12px", textAlign:"left" }}
                                      className='border-0 pl-1' defaultValue={FuncUtil.toCurrencyRate(record[column.data], "BDT")} />
                                  </>
                                )
                                : (column.data === 'date' ?
                                  <input style={{ width: "60px" , fontSize:"12px", textAlign:"left" }}
                                    className='border-0 pl-1'
                                    type='text'
                                    onKeyDown={onKeyDown}
                                    onFocus={(e) => e.target.select()}
                                    onBlur={(e) => {
                                      clearTimeout(record[column.data].timer)
                                      const timer = setTimeout(() => getValues({ e, record, data: column.data }));

                                    }}
                                    defaultValue={formatDateTime(record.date)}
                                  />
                                  :
                                  (column.data === 'patientId' ? (record[column.data]) :
                                    (
                                      // <input style={{ marginLeft: "4px" }}
                                      //   onInput={(e) => {
                                      //     clearTimeout(record[column.data].timer)
                                      //     const timer = setTimeout(() => getValues({ e, record, data: column.data }), 5000);
                                      //   }}
                                      //   defaultValue={record[column.data]}
                                      //   className='border-0 pl-1'
                                      // />
                                      
                                      <input
                                      type='text'
                                        onKeyDown={onKeyDown}
                                        onFocus={(e) => e.target.select()}
                                        onBlur={(e) => {
                                          if (record[column.data] && record[column.data].timer) {
                                            clearTimeout(record[column.data].timer);
                                          }
                                          const timer = setTimeout(() => getValues({ e, record, data: column.data }));
                                        }}
                                        defaultValue={record[column.data]}
                                        className='border-0 pl-1'
                                        style={{width: "-webkit-fill-available ",
                                        boxSizing: 'border-box',fontSize:"12px", textAlign:"left" }}
                                      />
                                    )
                                  )
                                
                                
                              )
                              )
                          }
                        </td>
                    ))}
                  </tr>
                ))}

              </tbody>

              <tfoot>
                {isLoading ? <tr key={'foot-tr-loading'}>
                  <td colSpan={columns.length} className={"text-center"}>Loading...</td>
                </tr> : ""}
                {!isLoading ?
                  <tr key={'foot-tr-1'} >
                    {columns.map((column: any, index: number) => (
                      column["calculateSum"] ?
                        <td key={index} className={column.class} style={{ width: "100px", fontSize:"12px", textAlign:"left" }}>
                          <strong>
                            {
                              column.currency ? FuncUtil.toCurrency(calculateSum(column.data), "BDT") : calculateSum(column.data)
                            }
                          </strong>
                        </td> :
                        <td key={'foot-' + index} className={column.class}></td>
                    ))}
                  </tr> : ""
                }
              </tfoot>
            </table>
          </div>
          <div className={'w-100 datatable-download'} id={'datatable-download'}>
            <table className={'table-bordered m-1'} id={'datatable-print'}>
              <thead>
                <tr>
                  <td style={{ border: '0px' }} colSpan={columns.filter((column: any) => { return column.name !== 'Action' }).length}>
                    Data From {searchParams.from} TO {searchParams.to}
                  </td>

                </tr>
                <tr>
                  {columns.map((column: any, index: number) => (
                    column.name !== 'Action' ?
                      <th key={'th' + index}>{column.name === 'Patient Name' ? 'Patient Name.________________________' : (column.name === 'Agency / Agent' ? 'Agency / Agent.________________________' : column.name)}</th>
                      : ''
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((record: any, index) => (
                  <tr key={'rec-' + index}>
                    {columns.map((column: any, cellNumber: number) => (
                      column.name !== 'Action' ? (
                        column["render"] ?
                          <td key={cellNumber} >{column.render(record)}</td>
                          : <td key={cellNumber}>
                            {column.data === 'index' ? index + 1 : (column.data === 'date' ? formatDateTime(record.date) : record[column.data])}
                          </td>) : ''
                    ))}
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  {columns.map((column: any, index: number) => (
                    column["calculateSum"] ?
                      <td key={index} className={column.class}>
                        <strong>
                          {
                            column.currency ? FuncUtil.toCurrency(calculateSum(column.data), "BDT") : calculateSum(column.data)
                          }
                        </strong>
                      </td> :
                      <td key={'foot-' + index} className={column.class}></td>
                  ))}
                </tr>
              </tfoot>
            </table>

          </div>
        </div>
        <div className={"col-12"}>
          {isFailed ? (
            <div className="alert alert-danger mb-1 alert-icon-left" role="alert">
              <span className="alert-icon"> <i className="ft-alert-circle"></i> </span>
              {message}
            </div>) : ''}
        </div>
        <div className={"col-12"}>
          <div className={"row"}>
            <div className={"col-8 mt-1"}>
              <select className="form-control pull-left width-20-per" onChange={handlePageSize}>
                {pagesSizes ? pagesSizes.map((column: any, index: number) => (
                    <option key={'size' + index} value={column}>{column} Record</option>
                )) : (<>
                      <option value={10}>10 Record</option>
                      <option value={20}>20 Record</option>
                      <option value={30}>30 Record</option>
                      <option value={50}>50 Record</option>
                      <option value={100}>100 Record</option>
                      <option value={9999} >All Record</option>
                    </>
                )}

              </select>
              <div className="dataTables_info pull-left ml-2 mt-1" id="DataTables_Table_1_info" role="status"
                   aria-live="polite">
                Showing {pagination.size * pagination.page} to {(pagination.size * pagination.page )+pagination.size} of {pagination.totalElements} entries
              </div>
            </div>
            <div className={"col-4"}>
              <nav className="pull-right" aria-label="Page navigation">
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link"
                            onClick={() => handlePageChange(pagination.page - 1)}
                            disabled={pagination.page === 0}
                            aria-label="Previous">
                      <span aria-hidden="true">Prev</span>
                      <span className="sr-only">Previous</span>
                    </button>
                  </li>
                  <span className="mr-1 ml-1"> Page {pagination.page + 1} of {Math.ceil(pagination.totalElements / pagination.size)}</span>
                  <li className="page-item">
                    <button className="page-link"
                            onClick={() => handlePageChange(pagination.page + 1)}
                            disabled={pagination.totalPages === pagination.page}
                            aria-label="Next">
                      <span aria-hidden="true">Next</span>
                      <span className="sr-only">Next</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default ActualBillTable;
